import { createTheme, styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title1: React.CSSProperties;
    underline: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title1?: React.CSSProperties;
    underline?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true;
    selected: true;
    light: true;
    textonly: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true;
    underline: true;
  }
}

export const appTheme = createTheme({
  typography: {
    title1: {
      fontWeight: 900,
      fontSize: 24,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
    },
    underline: {
      fontWeight: 900,
      fontSize: 16,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'none',
            border: '1px solid #E6EAEE',
            color: '#000000',
            fontWeight: 400,
            '&:hover': {
              border: '1px solid #1775E0',
              background: '#1775E0',
              color: '#FFFFFF',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        },
        {
          props: { variant: 'selected' },
          style: {
            textTransform: 'none',
            background: '#1775E0',
            color: '#FFFFFF',
            fontWeight: 400,
            '&:hover': {
              background: '#1775E0',
              color: '#FFFFFF',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        },
        {
          props: { variant: 'light' },
          style: {
            textTransform: 'none',
            border: '1px solid #EAF1F8',
            background: '#EAF1F8',
            fontWeight: 400,
            color: '#000',
            '&:hover': {
              border: '1px solid #1775E0',
              background: '#1775E0',
              color: '#FFFFFF',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        },
        {
          props: { variant: 'textonly' },
          style: {
            textTransform: 'none',
            fontWeight: 400,
            color: '#000',
            borderBottom: '2px solid black',
            borderRadius: 0,
            paddingBottom: 0,
            '&:hover': {
              background: 'none',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { className: 'outlined' },
          style: {
            textTransform: 'none',
            border: '1px solid #E6EAEE',
            borderRadius: '4px',
            color: '#000000',
            fontWeight: 400,
            '&:hover': {
              border: '1px solid #1775E0',
              background: '#1775E0',
              color: '#FFFFFF',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        },
      ],
    },
  },
})

export const PDIprimaryButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 900,
  padding: '16px 24px',
  lineHeight: 1.5,
  color: '#FFFFFF',
  backgroundColor: '#1775E0',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#0069d9',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: '#1775e052',
    color: '#FFFFFF',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
})

export default appTheme
