import { ReactElement, useEffect, useRef } from 'react'
import { Backdrop, Dialog, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import dropdown from 'assets/icons/dropdown.svg'
import terms from 'common/terms'
import FileCard from 'components/FileCard/FileCard'
import Loader from 'components/Loader'
import DateDialog from 'components/DateDialog/DateDialog'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'
import Dropzone, { FileRejection } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { setError } from 'reducers/feedback'
import {
  fileUploadComplete, showImport, updateFileToBeUploaded, togglDdisplayDateDialog,
} from 'reducers/paa'
import PaaServices from 'services/PaaServices'
import { ImportStatusEnum } from 'services/PaaServices/types'
import { RootState } from 'Store'
import { PDIprimaryButton } from 'themes/theme'
import './UploadDialog.scss'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { red } from '@mui/material/colors'
import InfoIcon from '@mui/icons-material/Info'
import checkPendingOrFailedImports from './utils'

const acceptedTypes = { 'text/plain': ['.txt'], 'application/zip': ['.zip'] }

export default function UploadDialog(): ReactElement {
  const dispatch = useDispatch()
  const {
    importList, importsLoading, sendImportLoading, numberSentFiles, importErrors,
    fileToBeUploaded, displayDateDialog,
  } = useSelector((state: RootState) => state.paa)
  const fetchStatus = useRef<NodeJS.Timer | NodeJS.Timeout>()
  const init = useRef<boolean>()

  const onDropAccepted = (files: File[]) => {
    const array = cloneDeep(fileToBeUploaded)
    files.forEach((file:any, index) => {
      array.push({
        file_name: file.name,
        file_size: file.size,
        import_from: dayjs('2022-04-17T00:00'),
        import_until: dayjs('2022-04-17T23:59'),
        order: index,
        file,
      })
    })
    dispatch(togglDdisplayDateDialog(true))
    dispatch(updateFileToBeUploaded(array))
  }

  const uploadFiles = () => {
    if (fileToBeUploaded.length) {
      fileToBeUploaded.forEach((file: any, index: any) => {
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('filename', file.file_name)
        formData.append('import_from', dayjs(file.import_from).format('HH:mm:ss'))
        formData.append('import_until', dayjs(file.import_until).format('HH:mm:ss'))
        formData.append('order', importList.length + index)
        const object = { formData }
        dispatch(PaaServices.sendImport(object.formData))
      })
    }
  }

  const clearFetchInterval = () => {
    clearInterval(fetchStatus.current as NodeJS.Timeout)
    dispatch(fileUploadComplete())
    fetchStatus.current = undefined
  }

  const sendingFiles = () => (numberSentFiles > 0)

  const onDropRejected = (rejection: FileRejection[]) => {
    switch (rejection[0].errors[0].code) {
      case 'file-invalid-type':
        dispatch(setError({ code: 400, data: { message: 'Error.invalidType', messageReady: true } }))
        break

      case 'file-too-large':
        dispatch(setError({ code: 400, data: { message: 'Error.fileTooLarge', messageReady: true } }))
        break

      default:
        dispatch(setError({ code: 400, data: { message: 'Error.unknown', messageReady: true } }))
    }
  }

  const checkStatus = () => {
    if (fetchStatus.current === undefined && importList.some(file => file.status === ImportStatusEnum.pending)) {
      fetchStatus.current = (setInterval(() => dispatch(PaaServices.getImports()),
        2000))
      return
    }

    if (importList.every(file => file.status !== ImportStatusEnum.pending) && !sendingFiles()) { clearFetchInterval() }
  }

  useEffect(() => {
    checkStatus()
  }, [])

  useEffect(() => {
    checkStatus()
  }, [importList])

  useEffect(() => {
    if (!importsLoading && !init.current) {
      init.current = true
    }
  }, [importsLoading])

  return (
    <>
      <DateDialog />
      <Dialog className="dashboard-dialog" open={!displayDateDialog}>
        <Typography variant="title1" mb={3}>
          {terms.Import.title}
        </Typography>
        {importList.length > 0 || fileToBeUploaded.length > 0 ? (
          <Typography variant="body1" mb={3}>
            {terms.Import.subtitle}
          </Typography>
        ) : (
          <Typography variant="body1" mb={3}>
            {terms.Import.subtitleEmpty}
          </Typography>
        ) }

        <div className="dropzone">
          <Backdrop
            open={sendImportLoading || (!init.current && importsLoading)}
            className="file-backdrop"
          >
            <Loader />
          </Backdrop>
          <Dropzone
            maxSize={100000000}
            onDropRejected={reject => onDropRejected(reject)}
            accept={acceptedTypes}
            onDropAccepted={file => onDropAccepted(file)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img src={dropdown} alt="" width={45} />
                <Typography variant="body1" mt={2}>
                  {terms.Import.dragFile}
                </Typography>
                <Typography variant="underline">
                  {terms.Import.dragFile2}
                </Typography>
              </div>
            )}
          </Dropzone>
        </div>
        {importErrors.length > 0
        && (
          <div className="d-flex align-items-center mb-1" style={{ color: red[500] }}>
            <WarningAmberIcon />
            {terms.Import.fileNotImported}
            <Tooltip title={importErrors.join('\n')}>
              <InfoIcon className="error-info" />
            </Tooltip>
          </div>
        )}
        <div className="overflow-auto">
          <div className="file-container">
            {fileToBeUploaded.length > 0 && (
            <span>
              {fileToBeUploaded.length > 1
                ? 'Fichiers prêts à être importé' : 'Fichier prêt à être importé'}
            </span>
            )}

            {fileToBeUploaded?.map((file: any) => (
              <FileCard key={file.order} file={file} type="tobeuploaded" />
            ))}
          </div>
          <div className="file-container">
            {importList.length > 0 && (
            <span>
              {importList.length > 1
                ? 'Fichiers importés' : 'Fichier importé'}
            </span>
            )}
            {importList?.map((file: any) => (
              <FileCard key={file.id} file={file} type="uploaded" />
            ))}
          </div>
        </div>
        {sendingFiles() && <Loader message={terms.Import.uploadPending} />}
        {fileToBeUploaded.length !== 0 || importList.length === 0 ? (
          <PDIprimaryButton
            disabled={fileToBeUploaded.length === 0}
            onClick={() => uploadFiles()}
          >
            Importer les fichier

          </PDIprimaryButton>
        ) : (
          <PDIprimaryButton
            disabled={checkPendingOrFailedImports(importList) || sendingFiles()}
            onClick={() => dispatch(showImport())}
          >
            {terms.Import.buttonImport}

          </PDIprimaryButton>
        )}

      </Dialog>
    </>

  )
}
