/* eslint-disable react/no-array-index-key */
import {
  Backdrop,
  Pagination,
  Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import FilterSelect from 'components/FilterSelect/FilterSelect'
import InfoPanel, { Severity } from 'components/InfoPanel/InfoPanel'
import Loader from 'components/Loader'
import ToolBar from 'components/ToolBar/ToolBar'
import { allFieldsVisibility } from 'components/VisibilityMenu/utils'
import {
  ReactElement, useEffect,
  useRef,
  useState,
} from 'react'
import nextId from 'react-id-generator'
import { useDispatch, useSelector } from 'react-redux'
import PaaServices from 'services/PaaServices'
import './DataTable.scss'
import AddIcon from '@mui/icons-material/Add'
import DataTableHeader from './DataTableHeader'
import ModalDataTable from './ModalDataTable/ModalDataTable'

export default function DataTable(): ReactElement {
  const dispatch = useDispatch()
  const tableRef = useRef<null | HTMLDivElement>(null)
  const {
    activeType, importData, importDataLoading, models, ordering, filterMenuOpen, filters, columnsVisibility,
  } = useSelector((state: RootState) => state.paa)
  const [page, setPage] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)
  const [currentRowId, setCurrentRowId] = useState<string | number>('')

  useEffect(() => {
    dispatch(PaaServices.getModels())
    dispatch(PaaServices.getOrdering())
    dispatch(PaaServices.getFilters())
  }, [])

  useEffect(() => {
    setPage(1)
    dispatch(PaaServices.getDataForModel({ model: activeType, page: 1 }))
    tableRef.current?.scroll(0, 0)
  }, [activeType, filters])

  useEffect(() => {
    dispatch(PaaServices.getDataForModel({ model: activeType, page }))
  }, [ordering])

  const onPageChange = (newPage: number) => {
    tableRef.current?.scroll(0, 0)
    setPage(newPage)
    dispatch(PaaServices.getDataForModel({ model: activeType, page: newPage }))
  }

  const handleClickRowCDI = (rowId: string | number) => {
    if (activeType !== 'cdi') return

    setCurrentRowId(rowId)

    dispatch(PaaServices.getDataByIdForModel({
      model: activeType,
      id: rowId,
      page: 1,
    }))
    setModalOpen(true)
  }

  const renderData = () => {
    const noData = (!importData.results.length && !importDataLoading)
    if (noData
      || (
        allFieldsVisibility(columnsVisibility[activeType], false)
        && columnsVisibility[activeType].length
        && !importDataLoading
      )
    ) {
      return (
        <TableRow className="empty-row">
          <TableCell colSpan={models.find(model => model.slug === activeType)?.fields.length}>
            <InfoPanel
              title={noData ? terms.Common.noResults : terms.Table.hiddenColumns}
              severity={Severity.warning}
            />
          </TableCell>
        </TableRow>
      )
    }

    const indexes = columnsVisibility[activeType]?.flatMap(
      (field, index) => ((field.visible && !field.disabled_by_default) ? index : []),
    )

    return (
      indexes && importData.results?.map(row => (
        <TableRow
          key={nextId()}
          onClick={() => handleClickRowCDI(row[0])}
          className={activeType === 'cdi' ? 'table-row-cdi' : ''}
        >
          {
            activeType === 'cdi' && (
              <TableCell
                key={nextId()}
                className="table-row-cdi"
              >
                <AddIcon fontSize="small" />
              </TableCell>
            )
          }
          {row.filter((_, index) => indexes.includes(index)).map(cell => (

            <TableCell
              key={nextId()}
              className={activeType === 'cdi' ? 'table-row-cdi' : ''}
            >
              {cell}
            </TableCell>

          ))}
        </TableRow>
      )))
  }

  return (
    <>
      <ToolBar />
      {filterMenuOpen ? (
        <div className="filter-wrapper">
          <FilterSelect />
        </div>
      ) : null}
      <TableContainer ref={tableRef} className="table-container position-relative">
        {importDataLoading && (
        <Backdrop
          open
          className="table-backdrop"
        >
          <Loader />
        </Backdrop>
        )}

        <Table stickyHeader style={{ height: 'auto' }}>
          <DataTableHeader />
          <TableBody>
            {renderData()}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className="mt-1 d-flex justify-content-center"
        onChange={(_evt, newPage: number) => onPageChange(newPage)}
        variant="outlined"
        page={page || undefined}
        shape="rounded"
        boundaryCount={1}
        siblingCount={1}
        count={Math.ceil(importData.count / 1000) || 1}
      />

      <ModalDataTable
        rowId={currentRowId as string}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </>
  )
}
