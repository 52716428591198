import DataTable from 'components/DataTable/DataTable'
import ExportDialog from 'components/ExportDialog/ExportDialog'
import FeedbackSnackBar from 'components/FeedbackSnackBar/FeedbackSnackBar'
import UploadDialog from 'components/UploadDialog/UploadDialog'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaaServices from 'services/PaaServices'
import { RootState } from 'Store'

export default function Home(): ReactElement {
  const dispatch = useDispatch()
  const {
    showImportModal, importList, showExportModal,
  } = useSelector((state: RootState) => state.paa)

  useEffect(() => {
    setTimeout(() => {
      dispatch(PaaServices.getImports())
    }, 500)
  }, [])

  const renderHome = () => {
    if (showImportModal) {
      return <UploadDialog />
    }
    if (importList.length) {
      return (
        <>
          {showExportModal
          && <ExportDialog />}
          <DataTable />
        </>
      )
    }
    return ''
  }

  return (
    <>
      {renderHome()}
      <FeedbackSnackBar />
    </>
  )
}
