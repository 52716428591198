import { ColumnVisiblity, Export, VisibilityField } from 'services/PaaServices/types'

const getCSSValue = (name: string): string => getComputedStyle(document.documentElement).getPropertyValue(name)

export const buildExport = (
  selectedModels: string[],
  columnsVisibility: ColumnVisiblity,
  rowId?: string,
): Export => {
  const currentModels = Object.keys(columnsVisibility).filter(key => selectedModels.includes(key))
  const columnsToExport = currentModels.map(key => ({
    model: key,
    columns: columnsVisibility[key].flatMap(
      (field: VisibilityField) => ((field.visible && !field.disabled_by_default) ? field.slug : []),
    ),
    expand: rowId,
  }))
  return { models: columnsToExport }
}

export const downloadFile = (exportFile: ArrayBuffer | null) => {
  if (exportFile) {
    const filename = new Date().toLocaleDateString('fr-FR')
    const url = window.URL.createObjectURL(new Blob([exportFile], { type: 'application/zip' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${filename}.zip`)
    document.body.appendChild(link)
    link.click()
  }
}

export default getCSSValue
