import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  Box, Button, Modal, Table, TableBody, TableContainer, TableRow, TableCell, Pagination,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { exportFileDownloaded } from 'reducers/paa'
import nextId from 'react-id-generator'
import terms from 'common/terms'
import PaaServices from 'services/PaaServices'
import { buildExport, downloadFile } from 'components/utils'
import DataTableHeader from '../DataTableHeader'
import './ModalDataTable.scss'

interface ModalDataTableProps {
  open: boolean
  handleClose: () => void
  rowId: string
}

const ModalDataTable = ({
  open, handleClose, rowId,
}: ModalDataTableProps): ReactElement => {
  const dispatch = useDispatch()
  const tableRef = useRef<null | HTMLDivElement>(null)
  const {
    importDataById, columnsVisibility, activeType, exportFile,
  } = useSelector((state: RootState) => state.paa)
  const [page, setPage] = useState(1)

  const indexes = columnsVisibility[activeType]?.flatMap(
    (field, index) => ((field.visible && !field.disabled_by_default) ? index : []),
  )

  const onPageChange = (newPage: number) => {
    tableRef.current?.scroll(0, 0)
    setPage(newPage)
    dispatch(PaaServices.getDataByIdForModel({
      model: activeType,
      id: rowId,
      page: newPage,
    }))
  }

  const clickToExport = () => {
    dispatch(PaaServices.getExport(buildExport([activeType], columnsVisibility, rowId)))
  }

  useEffect(() => {
    if (exportFile) {
      downloadFile(exportFile)
      dispatch(exportFileDownloaded())
    }
  }, [exportFile])

  return (
    <Modal open={open} onClose={handleClose} className="modal-data-table">
      <Box className="box">
        <div className="header">
          <Button
            onClick={handleClose}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            {terms.Common.goBack}
          </Button>
          <Button
            onClick={clickToExport}
            variant="contained"
            startIcon={<FileDownloadOutlinedIcon />}
          >
            {terms.Export.exportCDI}
          </Button>
        </div>
        <div className="body">
          <TableContainer className="table-container position-relative ">
            <Table stickyHeader style={{ height: 'auto' }}>
              <DataTableHeader sortable={false} />
              <TableBody>
                <>
                  {
                    importDataById?.results.map(row => (
                      <TableRow
                        key={nextId()}
                      >
                        {row.filter((_, index) => indexes.includes(index)).map(dataItem => (
                          <TableCell key={nextId()}>
                            {dataItem}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  }
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {
          importDataById.next && (
            <Pagination
              className="mt-1 d-flex justify-content-center"
              onChange={(_evt, newPage: number) => onPageChange(newPage)}
              variant="outlined"
              page={page}
              shape="rounded"
              boundaryCount={1}
              siblingCount={1}
              count={Math.ceil(importDataById.count / 1000) || 1}
            />
          )
        }

      </Box>
    </Modal>
  )
}

export default ModalDataTable
