import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, ClickAwayListener, IconButton } from '@mui/material'
import terms from 'common/terms'
import CustomSelect from 'components/Inputs/CustomSelect/CustomSelect'
import CustomTextField from 'components/Inputs/CustomTextField/CustomTextField'
import { debounce, get } from 'lodash'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showFilterMenu } from 'reducers/paa'
import PaaServices from 'services/PaaServices'
import { Filter, FilterOperator, ImportKind } from 'services/PaaServices/types'
import { RootState } from 'Store'
import './FilterSelect.scss'

const EMPTY_FILTER = (type: ImportKind, field: string) => (
  {
    id: 0,
    model: type,
    field,
    operator: FilterOperator.contains,
    value: '',
  }
)

type SelectItem = {
  label: string;
  value: string;
}

export default function FilterSelect(): ReactElement {
  const dispatch = useDispatch()
  const {
    activeType, models, filters,
  } = useSelector((state: RootState) => state.paa)
  const [selectFieldOpen, setSelectOpen] = useState(false)
  // todo refacto
  const [filterList, setFilterList] = useState<Filter[]>(filters.filter(filt => filt.model === activeType).length
    ? filters.filter(filt => filt.model === activeType)
    : [EMPTY_FILTER(activeType, models?.find(model => model.slug === activeType)?.fields[0].slug as string)])
  const debouncedFilterAdd = debounce((filter: Filter) => dispatch(PaaServices.createFilter(filter)), 500)
  const debouncedFilterUpdate = debounce((filter: Filter) => dispatch(PaaServices.updateFilter(filter)), 500)
  const init = useRef<boolean>()

  useEffect(() => {
    if (!init.current) {
      init.current = true
    }
    if (init.current && !filterList.length) {
      dispatch(showFilterMenu())
    }
  }, [filterList])

  useEffect(() => {
    const modelFilters = filters.filter(filt => filt.model === activeType)
    if (!init.current || !modelFilters.length) {
      return
    }

    setFilterList(modelFilters)
  }, [filters])

  const handleChange = (key: keyof Filter, value: string | FilterOperator, filt: Filter) => {
    const filter = { ...filt, [key]: value }
    const existingFilter = filters.find(elem => elem.id === filt.id) as Filter

    if (filter.value) {
      if (existingFilter !== undefined) {
        debouncedFilterUpdate(filter)
      } else {
        debouncedFilterAdd(filter)
      }
    } else {
      setFilterList(filterList.map(f => (f.id === filter.id ? filter : f)))
    }
  }

  const handleAddFilter = () => {
    const defaultField = models?.find(model => model.slug === activeType)?.fields[0].slug as string
    const newEmptyFilter = EMPTY_FILTER(activeType, defaultField)
    newEmptyFilter.id = filters[filters.length - 1].id + 1

    setFilterList([...filterList, newEmptyFilter])
  }

  const handleClickAway = () => {
    if (!selectFieldOpen) {
      dispatch(showFilterMenu())
    }
  }

  const handleOpen = () => {
    setSelectOpen(true)
  }

  const handleClose = () => {
    setSelectOpen(false)
  }

  const handleRemoveFilter = (filter: Filter) => {
    if (filters.findIndex(elem => elem.id === filter.id) === -1) {
      setFilterList(list => list.filter(elem => elem.id !== filter.id))
    } else {
      dispatch(PaaServices.deleteFilter(filter.id))
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="d-flex justify-content-center" id="filter-select">
        {filterList?.map(filter => (
          <div key={filter.id} className="d-flex justify-content-center align-items-end">
            <IconButton
              onClick={() => handleRemoveFilter(filter)}
            >
              <DeleteIcon />
            </IconButton>
            <div className="ml-3 param">
              <div className="input-title">{terms.Table.column}</div>
              <CustomSelect
                onOpen={handleOpen}
                onBlur={handleClose}
                items={(models.find(model => model.slug === activeType)
                  ?.fields)?.map(el => ({ label: el.name, value: el.slug })) as SelectItem[]}
                value={filter.field}
                onChange={(newValue: string) => { handleChange('field', newValue, filter) }}
              />
            </div>
            <div className="ml-3 param">
              <div className="input-title">{terms.Table.operator}</div>
              <CustomSelect
                onOpen={handleOpen}
                onBlur={handleClose}
                items={Object.keys(FilterOperator).map(op => ({
                  label: get(terms.Table, op),
                  value: FilterOperator[op as keyof typeof FilterOperator],
                }))}
                value={filter.operator}
                onChange={(newValue: string) => handleChange('operator', newValue, filter)}
              />
            </div>
            <div className="mx-3 param">
              <div className="input-title">{terms.Table.value}</div>
              <CustomTextField
                value={filter.value}
                onChange={(newValue: string) => handleChange('value', newValue, filter)}
                placeholder={filter.operator === FilterOperator.equals ? terms.Table.filterExample : ''}
                tooltip={filter.operator === FilterOperator.equals ? terms.Table.filterWithComma : ''}
              />
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-start align-items-start panel-footer mt-1 ml-2">
          <Button
            size="medium"
            disabled={filterList.some(elem => elem.value === '')}
            onClick={handleAddFilter}
            startIcon={<AddIcon />}
          >
            {terms.Table.addFilter}
          </Button>
        </div>
      </div>
    </ClickAwayListener>
  )
}
