/* eslint-disable no-restricted-properties */
import { Backdrop } from '@mui/material'
import { RootState } from 'Store'
import Loader from 'components/Loader'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateEndDate, updateStartDate,
} from 'reducers/paa'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Import, ImportStatusEnum } from 'services/PaaServices/types'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { frFR } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/fr'
import './FileCardDate.scss'

type Props = {
  file: Partial<Import>;
  order: number
}

export default function FileCardDate({ file, order }: Props): ReactElement {
  const dispatch = useDispatch()
  const { deleteLoading } = useSelector((state:RootState) => state.paa)

  const updateFileStartDate = (date: string) => {
    dispatch(updateStartDate({
      order,
      date,
    }))
  }

  const updateFileEndDate = (date: string) => {
    dispatch(updateEndDate({
      order,
      date,
    }))
  }

  return (
    <div style={{ position: 'relative' }}>
      <Backdrop
        open={file.status === ImportStatusEnum.pending || deleteLoading}
        className="file-backdrop"
      >
        <Loader />
      </Backdrop>
      <div className="d-flex flex-column p-3">
        <div className="ml-2 d-flex text-truncate">
          <span>{file.file_name}</span>
        </div>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <div className="mt-3 mb-2">
            <MobileTimePicker
              ampm={false}
              label="Heure de début"
              defaultValue={file.import_from}
              onChange={newValue => updateFileStartDate(newValue || '')}
              className="m-2"
            />
            <MobileTimePicker
              ampm={false}
              label="Heure de fin"
              defaultValue={file.import_until}
              onChange={newValue => updateFileEndDate(newValue || '')}
              className="m-2"
            />
          </div>
        </LocalizationProvider>
      </div>

    </div>
  )
}
