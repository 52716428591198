import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  Backdrop,
  Button, Checkbox, Dialog, FormControlLabel,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { RootState } from 'Store'
import terms from 'common/terms'
import Loader from 'components/Loader'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { exportFileDownloaded, showExport } from 'reducers/paa'
import PaaServices from 'services/PaaServices'
import { ImportKind } from 'services/PaaServices/types'
import { PDIprimaryButton } from 'themes/theme'
import { buildExport, downloadFile } from 'components/utils'

export default function ExportDialog(): ReactElement {
  const dispatch = useDispatch()
  const {
    exportLoading, models, exportFile, columnsVisibility,
  } = useSelector((state:RootState) => state.paa)
  const [modelsToExport, setExport] = useState<ImportKind[]>([])

  const onModelChange = (model: ImportKind, checked: boolean) => {
    if (checked) {
      setExport([...modelsToExport, model])
    } else {
      setExport(modelsToExport?.filter(value => value !== model))
    }
  }

  const onClick = () => {
    const columns = buildExport(modelsToExport, columnsVisibility)
    dispatch(PaaServices.getExport(columns))
  }

  useEffect(() => {
    if (exportFile) {
      downloadFile(exportFile)
      dispatch(exportFileDownloaded())
    }
  }, [exportFile])

  return (
    <Dialog className="dashboard-dialog" open>
      <Backdrop
        open={exportLoading}
        className="file-backdrop"
      >
        <Loader />
      </Backdrop>
      <Typography variant="title1" mb={3}>
        {terms.Export.title}
      </Typography>
      <Typography variant="body1" mb={3}>
        {terms.Export.subtitle}
      </Typography>

      <div className="d-flex flex-column">
        {models.map(model => (
          <FormControlLabel
            key={model.slug}
            className="mb-0"
            value=""
            control={(
              <Checkbox
                disableRipple
                onChange={e => onModelChange(model.slug, e.target.checked)}
                sx={{
                  color: '#C8C8C8',
                }}
              />
            )}
            label={model.name}
          />
        ))}
      </div>

      <PDIprimaryButton
        onClick={onClick}
        disabled={!modelsToExport.length}
        className="mt-4"
      >
        {terms.Export.fileExport}

      </PDIprimaryButton>

      <Button
        startIcon={<KeyboardBackspaceIcon />}
        onClick={() => dispatch(showExport())}
        sx={{
          width: 100,
        }}
        variant="textonly"
        className="mx-auto mt-5"
      >
        {terms.Common.goBack}

      </Button>
    </Dialog>
  )
}
