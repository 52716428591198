/* eslint-disable max-len */
const terms = {
  Common: {
    pendingQuery: 'Requête en cours...',
    continue: 'Continuer',
    goBack: 'Retour',
    noResults: 'Pas de données',
    validate: 'Valider',
  },
  Error: {
    invalidType: 'Seuls les formats .txt, et .zip sont autorisés.',
    tooManyFiles: 'Veuillez envoyer les fichiers séparément.',
    server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
    unknown: 'Une erreur est survenue.',
    notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
    badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
    conflict: 'Cet objet est en conflit. Réglez le conflit et réessayez.',
    forbidden: {
      appTitle: "Vous n'avez pas les droits nécessaires pour accéder à cette application",
      appSubtitle: 'Veuillez contacter votre administrateur',
      content: "Vous n'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.",
      exitButton: 'Retour au portail des applications',
    },
    fileTooLarge: 'Fichier trop volumineux (Max: 100Mo)',
  },
  Import: {
    title: 'Import des fichiers',
    subtitleEmpty: 'Veuillez importer votre premier fichier.',
    subtitle: 'Cela peut prendre quelques minutes si le fichier est volumineux. Les données seront supprimées de l\'outil au bout de quelques heures.',
    dragFile: 'Glissez-déposez le fichier ici ou',
    dragFile2: 'Sélectionnez le fichier sur votre ordinateur  (Max: 100Mo)',
    fileImport: 'Imports des fichiers',
    buttonImport: 'Visualiser les fichiers importés',
    deletionSuccess: 'Fichier supprimé',
    uploadPending: 'Fichiers en cours d\'envoi. Veuillez ne pas rafraichir',
    fileNotImported: 'Certains fichiers n\'ont pas été importés',
    dateTitle: 'Définir une plage horaire',
  },
  Export: {
    fileExport: 'Exporter',
    title: 'Exporter en xls',
    subtitle: 'Choisir les onglets à exporter dans le fichier',
    exportRunning: 'Export en cours...',
    exportDone: 'Export terminé',
    exportCDI: 'Extraire l\'opération',
  },
  Table: {
    addFilter: 'Ajouter un filtre',
    column: 'Colonne',
    operator: 'Opérateur',
    value: 'Valeur',
    contains: 'contient',
    equals: 'est égal à',
    startsWith: 'commence par',
    endsWith: 'finit par',
    greaterThan: 'supérieur ou égal à',
    lowerThan: 'inférieur ou égal à',
    exclude: 'exclure',
    filters: 'Filtres',
    filterWithComma: 'Filtrez sur plusieurs valeurs en les séparant avec une virgule (Valeur1, valeur2..)',
    filterExample: 'Valeur1, valeur2',
    hiddenColumns: 'Toutes les colonnes sont masquées',
    showColumns: 'Sélectionner tout',
    hideColumns: 'Déselectionner tout',
  },
} as const

export default terms
