import { auth } from '@osrdata/app_core'
import 'App.scss'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import Loader from 'components/Loader'
import TopBar from 'components/TopBar/TopBar'
import history from 'customHistory'
import {
  ReactElement, Suspense, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Route,
  Router,
} from 'react-router-dom'
import { RootState } from 'Store'
import terms from 'terms.json'
import Home from 'views/Home'

const APP_NAME = 'PAA MNG'
const ACCESS_PERM = 'paa-mng::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [isLoading, setLoading] = useState(true)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
      setLoading(false)
    }
  }, [isLogged, appPermissions])

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar
            appName={APP_NAME}
            hasAccess={!isLoading && !accessDenied}
          />
          {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (accessDenied ? <AccessDenied /> : (
            <div id="app">
              <Route component={Home} path="/" />
            </div>
          ))}
        </Router>
      </Suspense>
    </>
  )
}
