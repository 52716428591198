import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  Badge, Button,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import VisibilityMenu from 'components/VisibilityMenu/VisibilityMenu'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setImportData,
  setType, showExport, showFilterMenu, showImport,
} from 'reducers/paa'
import { ImportKind } from 'services/PaaServices/types'
import './ToolBar.scss'

export default function ToolBar(): ReactElement {
  const dispatch = useDispatch()
  const {
    activeType, importList, models, filters, columnsVisibility,
  } = useSelector((state: RootState) => state.paa)

  const onTypeChange = (type: ImportKind) => {
    if (type !== activeType) {
      dispatch(setImportData())
      dispatch(setType(type))
    }
  }

  return (
    <div className="toolbar py-4 px-3 bg-white">
      <div className="import-buttons">
        <Badge
          className="file-badge"
          color="secondary"
          badgeContent={importList.length}
        >
          <Button
            onClick={() => dispatch(showImport())}
            variant="outlined"
            startIcon={<CloudUploadOutlinedIcon />}
          >
            {terms.Import.fileImport}

          </Button>
        </Badge>

        <VisibilityMenu fields={columnsVisibility[activeType]} model={activeType} />
      </div>
      <div>
        {models.map(model => (
          <Button
            variant={model.slug === activeType ? 'selected' : 'outlined'}
            onClick={() => onTypeChange(model.slug as ImportKind)}
            key={model.slug + model.name}
            disableRipple
            className="mx-2"
          >
            {model.name}

          </Button>
        ))}
      </div>
      <div>
        <Badge
          className="file-badge mr-4"
          color="primary"
          badgeContent={filters.filter(filter => filter.model === activeType).length}
        >
          <Button
            onClick={() => dispatch(showFilterMenu())}
            variant="light"
            startIcon={<FilterAltIcon />}
            disableRipple
          >
            {terms.Table.filters}
          </Button>
        </Badge>
        <Button
          onClick={() => dispatch(showExport())}
          variant="light"
          startIcon={<FileDownloadOutlinedIcon />}
          disableRipple
        >
          {terms.Export.fileExport}

        </Button>
      </div>
    </div>
  )
}
