import { ReactElement } from 'react'
import { Dialog } from '@mui/material'
import terms from 'common/terms'
import Typography from '@mui/material/Typography'
import FileCardDate from 'components/FileCardDate/FileCardDate'
import { togglDdisplayDateDialog } from 'reducers/paa'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { PDIprimaryButton } from 'themes/theme'
import './DateDialog.scss'
import { Import } from 'services/PaaServices/types'

export default function DateDialog(): ReactElement {
  const dispatch = useDispatch()
  const {
    fileToBeUploaded, displayDateDialog,
  } = useSelector((state: RootState) => state.paa)

  return (
    <>
      <Dialog className="date-dialog" open={displayDateDialog}>
        <Typography variant="title1" mb={3}>
          {terms.Import.dateTitle}
        </Typography>
        <div className="file-container">
          {fileToBeUploaded?.map((file: Partial<Import>, index: number) => (
            <FileCardDate key={file.order} file={file} order={index} />
          ))}
        </div>
        <PDIprimaryButton
          disabled={fileToBeUploaded.some((
            file: Partial<Import>,
          ) => Object.values(file).some(value => value === undefined))}
          onClick={() => dispatch(togglDdisplayDateDialog(false))}
        >
          {terms.Common.validate}
        </PDIprimaryButton>
      </Dialog>
    </>

  )
}
