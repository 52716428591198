/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'services/uri'
import {
  Export,
  Filter,
  Import,
  Model, Order,
} from './types'

const getImports = createAsyncThunk(
  'paa/getImports',
  async (_v, thunkApi) => {
    try {
      const response = await get(`/${URI.paa}/imports/`)
      return response as Import[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getImport = createAsyncThunk(
  'paa/getImport',
  async (id: number, thunkApi) => {
    try {
      const response = await get(`/${URI.paa}/imports/${id}`)
      return response as Import
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const sendImport = createAsyncThunk(
  'paa/sendImport',
  async (fileObject: FormData, thunkApi) => {
    try {
      const response = await post(`/${URI.paa}/imports/`, fileObject)
      return response as Import
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteImport = createAsyncThunk(
  'paa/deleteImport',
  async (id: number, thunkApi) => {
    try {
      await deleteRequest(`/${URI.paa}/imports/${id}`)
      return id
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getModels = createAsyncThunk(
  'paa/getModels',
  async (_v, thunkApi) => {
    try {
      const response = await get(`/${URI.paa}/models/`)
      return response as Model[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDataForModel = createAsyncThunk(
  'paa/getDataForModel',
  async ({ model, page }: {model: string, page:number}, thunkApi) => {
    try {
      const response = await get(`/${URI.paa}/data/${model}`, { page })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDataByIdForModel = createAsyncThunk(
  'paa/getDataByIdForModel',
  async ({ model, id, page }: {model: string, id:string | number, page: number}, thunkApi) => {
    try {
      const response = await get(`/${URI.paa}/data/${model}/expand/${id}`, { page })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getExport = createAsyncThunk(
  'paa/getExport',
  async (models: Export, thunkApi) => {
    try {
      const response = await post(`/${URI.paa}/export/`, models, { responseType: 'blob' })
      return response as ArrayBuffer
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getOrdering = createAsyncThunk(
  'paa/getOrdering',
  async (_v, thunkApi) => {
    try {
      const response = await get(`/${URI.paa}/ordering/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const sendOrdering = createAsyncThunk(
  'paa/sendOrdering',
  async (order: Order, thunkApi) => {
    try {
      const response = await post(`/${URI.paa}/ordering/`, order)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getFilters = createAsyncThunk(
  'paa/getFilters',
  async (_v, thunkApi) => {
    try {
      const response = await get(`/${URI.paa}/filters/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const createFilter = createAsyncThunk(
  'paa/createFilter',
  async (filter: Filter, thunkApi) => {
    try {
      const response = await post(`/${URI.paa}/filters/`, filter)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteFilter = createAsyncThunk(
  'paa/deleteFilter',
  async (id: number, thunkApi) => {
    try {
      await deleteRequest(`/${URI.paa}/filters/${id}`)
      return id
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const updateFilter = createAsyncThunk(
  'paa/updateFilter',
  async (filter: Filter, thunkApi) => {
    try {
      const response = await patch(`/${URI.paa}/filters/${filter.id}`, filter)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const PaaServices = {
  getImports,
  getImport,
  sendImport,
  deleteImport,
  getModels,
  getDataForModel,
  getDataByIdForModel,
  getExport,
  getOrdering,
  sendOrdering,
  getFilters,
  createFilter,
  deleteFilter,
  updateFilter,
}

export default PaaServices
