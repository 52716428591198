import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Badge,
  Checkbox, FormControlLabel, FormGroup, IconButton, Popover,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  MouseEvent, ReactElement,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setColumnVisibility, showVisibilityMenu } from 'reducers/paa'
import { VisibilityField } from 'services/PaaServices/types'
import './VisibilityMenu.scss'
import { allFieldsVisibility } from './utils'

type Props = {
  model: string;
  fields: VisibilityField[] | undefined;
}
export default function VisibilityMenu({ model, fields }: Props): ReactElement {
  const dispatch = useDispatch()
  const { visibilityMenuOpen, columnsVisibility } = useSelector((state: RootState) => state.paa)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    dispatch(showVisibilityMenu())
  }

  const handleClose = () => {
    setAnchorEl(null)
    dispatch(showVisibilityMenu())
  }

  const handleVisiblityChange = (field: VisibilityField) => {
    dispatch(setColumnVisibility({ key: model, column: field }))
  }

  const allFieldsShown = allFieldsVisibility(fields, true)

  const badgeContent = () => columnsVisibility[model]?.filter(
    field => field.visible && !field.disabled_by_default,
  ).length

  return (
    <>
      <Badge
        className="file-badge"
        color="secondary"
        badgeContent={badgeContent()}
      >
        <IconButton
          disabled={!fields?.length}
          className="outlined"
          onClick={e => { handleClick(e) }}
        >
          <VisibilityIcon />
        </IconButton>

      </Badge>
      <Popover
        anchorEl={anchorEl}
        open={visibilityMenuOpen}
        className="visibility"
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >

        <FormGroup>

          <FormControlLabel
            control={(
              <Checkbox
                onChange={() => dispatch(setColumnVisibility({ key: model, select: !allFieldsShown }))}
                checked={allFieldsShown}
              />
)}
            label={!allFieldsShown ? terms.Table.showColumns : terms.Table.hideColumns}
          />
          {fields?.map(field => (
            !field.disabled_by_default && (
              <FormControlLabel
                key={field.slug}
                control={<Checkbox onChange={() => handleVisiblityChange(field)} checked={field.visible} />}
                label={field.name}
              />
            )
          ))}
        </FormGroup>
      </Popover>
    </>
  )
}
