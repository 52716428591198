import { isAnyOf } from '@reduxjs/toolkit'
import PaaServices from 'services/PaaServices'

export const postErrorMatcher = isAnyOf(
  PaaServices.getExport.rejected,
)

export const postPendingMatcher = isAnyOf(
  PaaServices.sendImport.pending,
)
