/* eslint-disable no-restricted-properties */
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import Chip from '@mui/material/Chip'
import InfoIcon from '@mui/icons-material/Info'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Backdrop, IconButton, Tooltip } from '@mui/material'
import { green, orange, red } from '@mui/material/colors'
import { RootState } from 'Store'
import {
  updateFileToBeUploaded,
} from 'reducers/paa'
import Loader from 'components/Loader'
import dayjs from 'dayjs'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaaServices from 'services/PaaServices'
import { Import, ImportStatusEnum } from 'services/PaaServices/types'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import './FileCard.scss'

dayjs.extend(customParseFormat)
type Props = {
  file: Partial<Import>;
  type: string;
}

export default function FileCard({ file, type }: Props): ReactElement {
  const dispatch = useDispatch()
  const { deleteLoading, fileToBeUploaded } = useSelector((state:RootState) => state.paa)

  const renderStatus = () => {
    if (file.status === ImportStatusEnum.failed) {
      return <CancelRoundedIcon sx={{ color: red[500] }} />
    }
    if (file.fail_message) {
      return <WarningAmberIcon sx={{ color: orange[500] }} />
    }
    if (file.status === ImportStatusEnum.imported) {
      return <CheckCircleRoundedIcon sx={{ color: green[500] }} />
    }
    return <></>
  }

  function formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals || 2
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const onClick = () => {
    if (type === 'tobeuploaded') {
      dispatch(updateFileToBeUploaded(fileToBeUploaded.filter((obj: Partial<Import>) => obj.order !== file.order)))
    } else {
      dispatch(PaaServices.deleteImport(file.id || 0))
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Backdrop
        open={file.status === ImportStatusEnum.pending || deleteLoading}
        className="file-backdrop"
      >
        <Loader />
      </Backdrop>
      <div className="d-flex align-items-center p-3">
        {renderStatus()}
        <div className="ml-2 d-flex flex-column text-truncate">
          <span>{file.file_name}</span>
          <span>{formatBytes(file.file_size || 0, 1)}</span>
        </div>
        <div className="ml-3">
          <Chip
            style={{ paddingTop: '1px' }}
            label={
              `${dayjs(
                file.import_from, 'HH:mm:ss',
              ).format('HH[h]mm')} - ${dayjs(file.import_until, 'HH:mm:ss').format('HH[h]mm')}`
            }
            color="primary"
          />
        </div>
        {file.fail_message && (
        <Tooltip title={file.fail_message}>
          <InfoIcon className="error-info" />
        </Tooltip>
        )}
        <IconButton
          disabled={deleteLoading || file.status === ImportStatusEnum.pending}
          onClick={onClick}
          className="ml-auto delete-button"
        >
          <CloseIcon />
        </IconButton>
      </div>

    </div>
  )
}
