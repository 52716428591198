import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  IconButton,
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showFilterMenu } from 'reducers/paa'
import { VisibilityField } from 'services/PaaServices/types'
import PaaServices from 'services/PaaServices'
import { RootState } from 'Store'
import { filterSlug, findOrder, nextOrder } from './Order'

import './DataTable.scss'

interface Props {
  sortable?: boolean
}

export default function DataTableHeader({ sortable }: Props): ReactElement {
  const dispatch = useDispatch()
  const {
    activeType, ordering, filters, columnsVisibility,
  } = useSelector((state: RootState) => state.paa)

  const changeOrder = (field: string) => () => {
    const newOrder = nextOrder(findOrder(field, ordering, activeType) as string)
    dispatch(PaaServices.sendOrdering({ model: activeType, ordering: `${field}__${newOrder}` }))
  }

  const activeOrdering = (item: VisibilityField) => {
    if (!sortable) return false
    return (ordering?.find(order => order.model === activeType
      && filterSlug(order.ordering) === item.slug) !== undefined)
  }

  return (
    <TableHead>
      <TableRow>
        {
          activeType === 'cdi' && sortable && (
            <TableCell />
          )
        }
        {columnsVisibility[activeType]?.filter(f => f.visible).map(field => (
          <TableCell key={field.slug} className={field.disabled_by_default ? 'hidden-column' : ''}>
            <TableSortLabel
              disabled={!sortable}
              hideSortIcon={!sortable}
              active={activeOrdering(field)}
              direction={findOrder(field.slug, ordering, activeType)}
              onClick={changeOrder(field.slug)}
            >
              {field.name}
            </TableSortLabel>
            {filters.findIndex(filter => filter.model === activeType && filter.field === field.slug) !== -1 && (
              <IconButton size="small" onClick={() => dispatch(showFilterMenu())}>
                <FilterAltIcon className="filter-icon" />
              </IconButton>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

DataTableHeader.defaultProps = {
  sortable: true,
}
