import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import PaaServices from 'services/PaaServices'
import {
  ColumnVisiblity,
  Filter,
  Import,
  ImportKind, Model, Order, VisibilityField,
} from 'services/PaaServices/types'
import { PaginatedImportData, ResponseError } from './types'

const emptyData = {
  count: 0, next: '', previous: '', results: [],
}
interface PaaState {
 importList: Import[];
 activeType: ImportKind;
 importData: PaginatedImportData;
 importDataById: PaginatedImportData;
 showImportModal: boolean;
 importsLoading: boolean;
 sendImportLoading: boolean;
 models: Model[];
 deleteLoading: boolean;
 showExportModal: boolean;
 exportLoading: boolean;
 exportFile: ArrayBuffer | null;
 importDataLoading: boolean;
 ordering: Order[];
 filters: Filter[];
 filterMenuOpen: boolean;
 activeMenu: string;
 numberSentFiles: number;
 importErrors: string[];
 visibilityMenuOpen: boolean;
 columnsVisibility: ColumnVisiblity;
 fileToBeUploaded: any
 displayDateDialog: boolean
 deleting: boolean
}

const initialState: PaaState = {
  importList: [],
  activeType: 'dialogue',
  importData: emptyData,
  importDataById: emptyData,
  showImportModal: true,
  importsLoading: true,
  sendImportLoading: false,
  models: [],
  deleteLoading: false,
  showExportModal: false,
  exportLoading: false,
  exportFile: null,
  importDataLoading: true,
  ordering: [],
  filters: [],
  filterMenuOpen: false,
  activeMenu: '',
  numberSentFiles: 0,
  importErrors: [],
  visibilityMenuOpen: false,
  columnsVisibility: {},
  fileToBeUploaded: [],
  displayDateDialog: false,
  deleting: false,
}

export const paaSlice = createSlice({
  name: 'paa',
  initialState,
  reducers: {
    setImportData: state => {
      state.importData = emptyData
    },
    setType: (state, action: PayloadAction<ImportKind>) => {
      state.activeType = action.payload
    },
    showImport: state => {
      state.showImportModal = !state.showImportModal
    },
    showExport: state => {
      state.showExportModal = !state.showExportModal
    },
    exportFileDownloaded: state => {
      state.exportFile = null
    },
    showFilterMenu: state => {
      state.filterMenuOpen = !state.filterMenuOpen
    },
    fileUploadComplete: state => {
      state.numberSentFiles = 0
    },
    showVisibilityMenu: state => {
      state.visibilityMenuOpen = !state.visibilityMenuOpen
    },
    setColumnVisibility: (state, action: PayloadAction<{key: string, column?: VisibilityField, select?: boolean}>) => {
      const { key, column, select } = action.payload

      if (typeof select === 'boolean') {
        state.columnsVisibility[key] = state.columnsVisibility[key].map(field => {
          if (field.disabled_by_default) {
            return { ...field, visible: true }
          }

          return { ...field, visible: select }
        })
      }
      if (column) {
        const index = state.columnsVisibility[key].findIndex(field => field.slug === column?.slug)
        state.columnsVisibility[key][index] = {
          ...state.columnsVisibility[key][index],
          visible: !state.columnsVisibility[key][index].visible,
        }
      }
    },
    updateFileToBeUploaded: (state, action: PayloadAction<any>) => {
      state.fileToBeUploaded = action.payload
    },
    updateEndDate: (state, action: PayloadAction<any>) => {
      state.fileToBeUploaded[action.payload.order].import_until = action.payload.date
      state.fileToBeUploaded[action.payload.order].order = action.payload.order
    },
    updateStartDate: (state, action: PayloadAction<any>) => {
      state.fileToBeUploaded[action.payload.order].import_from = action.payload.date
      state.fileToBeUploaded[action.payload.order].order = action.payload.order
    },
    togglDdisplayDateDialog: (state, action: PayloadAction<boolean>) => {
      state.displayDateDialog = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PaaServices.sendImport.fulfilled, (state, { payload }) => {
      if (!state.importList.some(({ id }) => id === payload.id)) {
        state.importList.push(payload)
      }
      state.numberSentFiles -= 1
      state.sendImportLoading = false
      state.fileToBeUploaded = []
    })
    builder.addCase(PaaServices.sendImport.pending, state => {
      state.sendImportLoading = true
      state.numberSentFiles += 1
    })
    builder.addCase(PaaServices.sendImport.rejected, (state, action) => {
      const errorMessage = (action.payload as ResponseError).data[0]
      if (typeof errorMessage === 'string' && !state.importErrors.includes(errorMessage)) {
        state.importErrors.push(errorMessage)
      }

      state.numberSentFiles -= 1
      state.sendImportLoading = false
    })
    builder.addCase(PaaServices.getImports.fulfilled, (state, { payload }) => {
      state.importList = payload
      if (!payload.length) {
        state.showImportModal = true
      }
      state.importsLoading = false
    })
    builder.addCase(PaaServices.getImports.pending, state => {
      state.importsLoading = true
    })
    builder.addCase(PaaServices.getImports.rejected, state => {
      state.importsLoading = false
    })
    builder.addCase(PaaServices.getModels.fulfilled, (state, { payload }) => {
      state.models = payload
      state.activeType = payload[0].slug
      state.columnsVisibility = payload.reduce((models, currentModel) => ({
        ...models,
        [currentModel.slug]: currentModel.fields.map(field => ({ ...field, visible: true })),
      }), {})
    })
    builder.addCase(PaaServices.getDataForModel.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.model === state.activeType) {
        state.importData = payload
        state.importDataLoading = false
      }
    })
    builder.addCase(PaaServices.getDataForModel.pending, state => {
      state.importDataLoading = true
    })
    builder.addCase(PaaServices.getDataForModel.rejected, state => {
      state.importData = emptyData
      state.importDataLoading = false
    })
    builder.addCase(PaaServices.getDataByIdForModel.fulfilled, (state, { payload }) => {
      state.importDataById = payload
      state.importDataLoading = false
    })
    builder.addCase(PaaServices.getDataByIdForModel.pending, state => {
      state.importDataLoading = true
    })
    builder.addCase(PaaServices.getDataByIdForModel.rejected, state => {
      state.importDataById = emptyData
      state.importDataLoading = false
    })
    builder.addCase(PaaServices.getImport.fulfilled, (state, { payload }) => {
      const index = state.importList.findIndex(({ id }) => id === payload.id)
      state.importList[index] = payload
    })
    builder.addCase(PaaServices.deleteImport.rejected, state => {
      state.deleteLoading = false
    })
    builder.addCase(PaaServices.deleteImport.pending, state => {
      state.deleteLoading = true
    })
    builder.addCase(PaaServices.deleteImport.fulfilled, (state, { payload }) => {
      state.deleteLoading = false
      state.importList = state.importList.filter(({ id }) => id !== payload)
    })
    builder.addCase(PaaServices.getExport.pending, state => {
      state.exportLoading = true
    })
    builder.addCase(PaaServices.getExport.rejected, state => {
      state.exportLoading = false
    })
    builder.addCase(PaaServices.getExport.fulfilled, (state, { payload }) => {
      state.exportFile = payload
      state.exportLoading = false
    })
    builder.addCase(PaaServices.getOrdering.fulfilled, (state, { payload }) => {
      state.ordering = payload
    })
    builder.addCase(PaaServices.sendOrdering.fulfilled, (state, { payload }) => {
      state.ordering = payload
    })
    builder.addCase(PaaServices.sendOrdering.pending, state => {
      state.importDataLoading = true
    })
    builder.addCase(PaaServices.sendOrdering.rejected, state => {
      state.importDataLoading = false
    })
    builder.addCase(PaaServices.getFilters.fulfilled, (state, { payload }) => {
      state.filters = payload
    })
    builder.addCase(PaaServices.createFilter.fulfilled, (state, { payload }) => {
      state.filters = [...state.filters, payload]
    })
    builder.addCase(PaaServices.deleteFilter.fulfilled, (state, { payload }) => {
      state.filters = state.filters.filter(({ id }) => id !== payload)
      if (!state.filters.filter(filter => filter.model === state.activeType).length) {
        state.filterMenuOpen = false
      }
    })
    builder.addCase(PaaServices.updateFilter.fulfilled, (state, { payload }) => {
      state.filters = state.filters.map(filter => (filter.id === payload.id ? payload : filter))
    })
  },
})

export const {
  setType, showImport, showExport, exportFileDownloaded, showFilterMenu,
  fileUploadComplete, showVisibilityMenu, setColumnVisibility, setImportData,
  updateFileToBeUploaded, togglDdisplayDateDialog, updateEndDate, updateStartDate,
} = paaSlice.actions

export default paaSlice.reducer
