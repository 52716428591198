import { VisibilityField } from 'services/PaaServices/types'

const allFieldsVisibility = (fields: VisibilityField[] | undefined, visible: boolean) => {
  // new array without disabled_by_default fields
  const fieldsWithoutDisableDefault: VisibilityField[] | undefined = fields?.filter(field => !field.disabled_by_default)
  // check if all fields have the same visibility
  return !!(fieldsWithoutDisableDefault?.every(
    item => item.visible === visible,
  ))
}

// eslint-disable-next-line import/prefer-default-export
export { allFieldsVisibility }
