/* eslint-disable camelcase */
export enum ImportKindEnum {
  organe = 'organe',
  dialogue = 'dialogue',
  cdi = 'cdi',
  alarme_front = 'alarme_front',
  etat_objet = 'etat_objet'
}

export enum ImportStatusEnum {
  failed = 'failed',
  pending = 'pending',
  imported = 'imported'
}

export type ImportKind = `${ImportKindEnum}`

export type ImportStatus = `${ImportStatusEnum}`

export type Import = {
  id: number;
  file_name: string;
  kind: ImportKind | null;
  file_size: number;
  status: ImportStatus;
  fail_message: string | null;
  order?: number;
  import_from?: string;
  import_until?: string;
}

export type Field = {
  slug: string;
  name: string;
  disabled_by_default: boolean;
}

export type Model = {
  slug: ImportKind;
  name: string;
  fields: Field[];
}

type AlarmeFront = {
  type_archive: string;
  date: string;
  numero: number;
  nom_module: string;
  nomMachine: string;
  libelle: string;
  niveau_exploitation: string;
  appuiSonore: number;
  libelle_long: string;
  profil: number;
  id: number;
  id_poste_enclenchement: number;
  id_zone_geographique: number;
  niveau_maintenance: string;
  libelle_maintenance: string;
  inhibition: string;
  liste_postes_operateurs: string;
  familleInstallation: string;
}

type Cdi = {
  type_archive: string;
  date: string;
  agent_circulation: string;
  type_PO: string;
  num_operation: number;
  unknown: string;
  date_creation: string;
  unknown_2: string;
  unknown_3: number;
  unknown_4: number;
  emetteur: string;
  data: string;
}

type Dialogue = {
  type_archive: string;
  date: string;
  numero: number;
  nom_module:string;
  nom_machine: string;
  libelle: string;
  id_poste_operateur: string;
  poste_enclenchement: number;
  zone_geographique: number;
  fonction_emettrice: string;
  machine_emettrice: string;
}

type EtatObjet = {
  type_archive: string;
  date: string;
}

type Organe = {
  type_archive: string;
  date: string;
  id_organe: string;
  id_poste_enclenchement: string;
  famille: string;
  rang: string;
  nom_module: string;
  nom_machine: string;
  etat: string;
}

export type ImportData = AlarmeFront | Cdi | Dialogue | EtatObjet | Organe

export type Order = {
  model: string;
  ordering: string;
}

export type TableOrder = 'asc' | 'desc' | undefined

export enum FilterOperator {
  contains = 'icontains',
  equals = 'iexact',
  startsWith = 'istartswith',
  endsWith = 'iendswith',
  greaterThan = 'gte',
  lowerThan = 'lte',
  exclude = 'exclude'
}

export type Filter = {
  id: number;
  model: string;
  field: string;
  operator: FilterOperator;
  value: string;
}

export type VisibilityField = Field & {
  visible: boolean;
}
export type ColumnVisiblity = {
  [key: string]: VisibilityField[];
}

type ExportModel = {
  model: string;
  columns: string[];
  expand?: string;
}

export type Export = {
  models: ExportModel[];
}
